import useDeviceType from '@/hooks/useDeviceType';
import { IFindYourPerfectEscapeProps } from './types';
import GridCards from './grid-cards';
import SliderCards from '@/components/base/slider-cards';
import { Container } from '@/components/base/gridview';
import { motion } from 'framer-motion';

const FindYourPerfectEscape: React.FC<IFindYourPerfectEscapeProps> = ({
  cards,
  firstCard,
  title,
  mbDesktop = 100,
  mtDesktop = 100,
  mtLaptop = 100,
  mbLaptop = 100,
  mbMobile = 50,
  mtMobile = 50,
}) => {
  const deviceType = useDeviceType();

  const checkDevice = () => {
    if (deviceType !== 'mobile' && deviceType !== 'tablet') {
      return <GridCards cards={cards} firstCard={firstCard} />;
    }

    return (
      <>
        <motion.h3>{title}</motion.h3>

        <SliderCards cards={cards} />
      </>
    );
  };

  return (
    <section
      className="find-your-perfect-escape"
      style={{
        marginTop:
          deviceType === 'mobile'
            ? mtMobile
            : deviceType === 'laptop' || deviceType === 'tablet'
            ? mtLaptop
            : mtDesktop,
        marginBottom:
          deviceType === 'mobile'
            ? mbMobile
            : deviceType === 'laptop' || deviceType === 'tablet'
            ? mbLaptop
            : mbDesktop,
      }}
    >
      <Container className="grid-cards-container">{checkDevice()}</Container>
    </section>
  );
};

export default FindYourPerfectEscape;
